import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SupermarketStaticNavPages } from '../../components/SupermarketStaticNavPages';
import { MenuBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "main-navigation"
    }}>{`Main Navigation`}</h1>
    <p><img alt="Main menu" src={require("../img/home1-main-menu.jpg")} /></p>
    <p>{`The main navigation automatically shows your web page links if it's visible.`}</p>
    <p>{`You can show or hide the web page links, or just show some custom links or custom text by editing the options in Theme Editor in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Page Navigation`}</strong>{`:`}</p>
    <p><img alt="theme editor page navigation" src={require("../img/theme-editor-page-navigation.png")} /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hide Content Pages in Main Menu`}</strong>{`: if checked, it will hide all web page links completely.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use manual links in Main Menu`}</strong>{`: if checked, it will allow you to show custom links or custom text by creating a specific banner in `}<strong parentName="li">{`Marketing`}</strong>{` > `}<strong parentName="li">{`Banners`}</strong>{`. Use the tool below to generate banner HTML code with your custom links.`}</li>
    </ul>
    <h2 {...{
      "id": "vertical-mega-menu-vs-slide-down-menu"
    }}>{`Vertical Mega Menu vs. Slide Down Menu`}</h2>
    <p><strong parentName="p">{`Note: the slide-down menu only works with the `}<inlineCode parentName="strong">{`Fixed Left Sidebar`}</inlineCode>{` layout.`}</strong></p>
    <p><strong parentName="p">{`Mega Menu`}</strong>{`:`}</p>
    <p><img alt="Mega menu" src={require("../img/home1-mega-menu.jpg")} /></p>
    <p><strong parentName="p">{`Slide Down Menu`}</strong>{`:`}</p>
    <p><img alt="Slidedown menu" src={require("../img/home1-slidedown-menu.jpg")} /></p>
    <p>{`You can switch the mega menu to slide down menu in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > tick to `}<strong parentName="p">{`Showing sub-categories vertical`}</strong>{`:`}</p>
    <p><img alt="subcategories vertical menu" src={require("../img/theme-editor-subcategories-vertical-menu.png")} /></p>
    <h2 {...{
      "id": "show-all-top-categories-menu-horizontally"
    }}>{`Show all top categories menu horizontally`}</h2>
    <p><img alt="horizontal category menu" src={require("../img/horizontal-category-menu.png")} /></p>
    <p>{`To display all top categories menu horizontally instead of displaying in the dropdown "Categories" item, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Main navigation`}</strong>{` > set `}<strong parentName="p">{`Show categories`}</strong>{` = `}<inlineCode parentName="p">{`Horizontal`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "use-flyout-menu"
    }}>{`Use flyout menu`}</h2>
    <p><img alt="flyout menu" src={require("../img/flyout-menu.png")} /></p>
    <p>{`Enable in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Main navigation`}</strong>{` > tick on `}<strong parentName="p">{`Use flyout menu instead of mega menu`}</strong>{`.`}</p>
    <h2 {...{
      "id": "show-custom-links-in-main-navigation"
    }}>{`Show custom links in Main Navigation`}</h2>
    <p>{`Theme allows you to input your custom links manually in the main navigation area. First, tick on `}<strong parentName="p">{`Use manual links in Main Menu`}</strong>{` checkout in `}<strong parentName="p">{`Theme Editor`}</strong>{`.`}</p>
    <p>{`Go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{` field, click on `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`, input the sample content below:`}</p>
    <SupermarketStaticNavPages mdxType="SupermarketStaticNavPages" />
    <p>{`Remember to choose other fields as below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Checked`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "show-banners-on-mega-menu"
    }}>{`Show banners on Mega Menu`}</h2>
    <p><img alt="Mega Menu Banners" src={require("../img/megamenu-banners.png")} /></p>
    <p>{`You can show custom banners on Mega Menu. Enable this feature in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Main Navigation`}</strong>{` > tick on `}<strong parentName="p">{`Show banners on Mega Menu`}</strong>{`. Click `}<strong parentName="p">{`Save`}</strong>{` then `}<strong parentName="p">{`Publish`}</strong>{`.`}</p>
    <p>{`Then go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In Banner Content editor, click on small `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    {
      /* <SupermarketMegaMenu /> */
    }
    <MenuBuilderComponent mdxType="MenuBuilderComponent" />
    <p>{`Remember to choose:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{` = `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{` = Yes`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{` = `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "limit-number-of-sub-categories-in-the-mega-menu-with-view-all-link"
    }}>{`Limit number of sub categories in the mega menu with View All link`}</h2>
    <p><img alt="limit-subcategories-in-mega-menu" src={require("../img/limit-subcategories-in-mega-menu.png")} /></p>
    <p>{`Go to `}<strong parentName="p">{`Storefront`}</strong>{` > `}<strong parentName="p">{`Script Manager`}</strong>{`, click `}<strong parentName="p">{`Create a Script`}</strong>{`, choose:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Location on page`}</strong>{` = `}<inlineCode parentName="li">{`Footer`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Select pages where script will be added`}</strong>{` = `}<inlineCode parentName="li">{`All Pages`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Script type`}</strong>{` = `}<inlineCode parentName="li">{`Script`}</inlineCode></li>
    </ul>
    <p>{`Enter the script below to `}<strong parentName="p">{`Scripts contents`}</strong>{`: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script>
    (function($) {
        var MAX_ITEMS = 5;
        var MORE_TEXT = 'View All';
        var LESS_TEXT = 'Collapse';

        
        $('<style/>').html(''
            + '@media (max-width: 800px) {'
            + '.navPage-childList-item--more, .navPage-childList-item--less { display: none }'
            + '}'
            + '@media (min-width: 801px) {'
            + '.navPage-childList-item--more a, .navPage-childList-item--less a { font-size: small; text-decoration: underline }'
            + '.navPage-childList-item--more.is-open { display: none }'
            + '.navPage-childList-item--more:not(.is-open) ~ .navPage-childList-item { display: none }'
            + '}')
            .appendTo('head');

        function ready() {
            $('.navPage-childList').each(function(i, el) {
                var $el =  $(el);
                if ($el.data('modShowMore')) {
                    return;
                }
                $el.data('modShowMore', true);
                if ($el.children().length > MAX_ITEMS) {
                    $('<li class="navPage-childList-item navPage-childList-item--more"><a class="navPage-childList-action navPages-action" href="#">' + MORE_TEXT + '</a></li>')
                        .insertAfter($el.children().eq(MAX_ITEMS));
                    $('<li class="navPage-childList-item navPage-childList-item--less"><a class="navPage-childList-action navPages-action" href="#">' + LESS_TEXT + '</a></li>')
                        .appendTo($el);

                    var $toggle = $el.children('.navPage-childList-item--more, .navPage-childList-item--less');
                    // console.log($toggle.children('a'));
                    $toggle.children('a').on('click', function(event) {
                        event.preventDefault();
                        $toggle.toggleClass('is-open');
                    });
                }
            });
        }
        
        $(document).ready(ready);
        $('body').on('loaded.instantload', ready);
    })(window.jQuerySupermarket);
</script>
`}</code></pre>
    <p>{`Edit `}<inlineCode parentName="p">{`MAX_ITEMS`}</inlineCode>{`, `}<inlineCode parentName="p">{`MORE_TEXT`}</inlineCode>{`, `}<inlineCode parentName="p">{`LESS_TEXT`}</inlineCode>{` values to change the maximum number of subcategories to show, View All link text, and Collapse link text.`}</p>
    <h2 {...{
      "id": "enable-main-navigation-auto-fit-to-a-single-line"
    }}>{`Enable Main Navigation auto fit to a single line`}</h2>
    <p>{`This feature is added from version 1.6.2.`}</p>
    <p>{`Go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Main Navigation`}</strong>{` > tick on `}<strong parentName="p">{`Fit to 1 line`}</strong></p>
    <p>{`If the number of menu items is longer than one line, it will automatically group into a "More" menu item, as shown in the screenshot below:`}</p>
    <p><img alt="main-nav-auto-fit" src={require("../img/main-nav-auto-fit.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      